import React, { Component, ReactNode } from 'react'
import { Modal, StyleSheet, TouchableOpacity, View } from 'react-native'
import { Image, Text } from 'react-native-elements'
import {
  appName,
  authTokenPath,
  buildName,
  isCPR,
  isMirror,
} from '../constants/Config'
import JSON from '../package.json'
import Colors from '../constants/Colors'
import originalSrc from '../variants/original/icon.png'
import mirrorSrc from '../variants/mirror/icon.png'
import manifest from '../web/manifest.json'
import Browser from '../constants/Browser'

const imgSrc = isMirror ? mirrorSrc : originalSrc

export interface IInstallPromptState {
  installDisabled: boolean
  hasprompt: boolean
  userInstalled: boolean
}

export class InstallPrompt extends Component<{}, IInstallPromptState> {
  public readonly state = {
    installDisabled: false,
    hasprompt: false,
    userInstalled: false,
  }

  public deferredprompt?: any

  public styles = StyleSheet.create({
    centeredView: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0,0,0,0.5)',
    },
    modalView: {
      margin: 20,
      backgroundColor: 'white',
      borderRadius: 20,
      padding: 10,
      shadowColor: '#000',
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      elevation: 5,
    },
    button: {
      backgroundColor: Colors.orangeWeb,
      borderRadius: 20,
      padding: 10,
      elevation: 2,
    },
    textStyle: {
      color: 'white',
      fontWeight: 'bold',
      textAlign: 'center',
    },
    head: {
      marginTop: 0,
      marginBottom: 24,
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
    },
    imagePlaceholder: { backgroundColor: 'transparent' },
    imageContainer: {
      backgroundColor: 'gray',
      borderRadius: 10,
    },
    image: { width: 56, height: 56 },
    headText: { marginLeft: 10, flex: 1 },
    title: { fontWeight: 'bold', fontSize: 24 },
    subtitle: { color: 'gray', fontSize: 16 },
    body: {
      marginBottom: 25,
      paddingTop: 10,
      borderTopWidth: 1,
      borderTopColor: 'gray',
    },
    sectionTitle: { fontWeight: 'bold', fontSize: 16, marginBottom: 10 },
  })

  constructor(props: {}) {
    super(props)

    window.addEventListener('beforeinstallprompt', (event: Event) => {
      event.preventDefault()
      this.deferredprompt = event
      this.setState({ ...this.state, hasprompt: true })
    })

    window.addEventListener('appinstalled', (evt) => {
      this.setState({ ...this.state, userInstalled: true })
    })
  }

  public async install(): Promise<boolean> {
    this.setState({ ...this.state, installDisabled: true })
    localStorage.removeItem(authTokenPath)
    if (this.deferredprompt) {
      this.deferredprompt.prompt()

      const choiceResult = await this.deferredprompt.userChoice

      if (choiceResult.outcome === 'accepted') {
        console.log('Your PWA has been installed')
        return true
      } else {
        console.log('User chose to not install your PWA')
      }
    }

    this.setState({ ...this.state, installDisabled: false })
    return false
  }

  public shouldShowBrowserPrompt(): boolean {
    return this.state.hasprompt && !this.state.userInstalled
  }

  public render(): ReactNode {
    if (!this.shouldShowBrowserPrompt() || !Browser.isSupportingBrowser)
      return null

    return (
      <Modal animationType="fade" transparent={true} visible={true}>
        <View style={this.styles.centeredView}>
          <View style={this.styles.modalView}>
            <View style={this.styles.head}>
              <Image
                placeholderStyle={this.styles.imagePlaceholder}
                containerStyle={this.styles.imageContainer}
                style={this.styles.image}
                resizeMode={'cover'}
                source={{ uri: imgSrc }}
              ></Image>
              <View style={this.styles.headText}>
                <Text style={this.styles.title}>
                  {manifest.name.replaceAll(
                    '@name',
                    isCPR ? buildName : appName
                  )}
                </Text>
                <Text style={this.styles.subtitle}>{`v${JSON.version}`}</Text>
              </View>
            </View>
            {!isCPR && (
              <View style={this.styles.body}>
                <Text style={this.styles.sectionTitle}>Részletek</Text>
                <Text>{manifest.description.replaceAll('@name', appName)}</Text>
              </View>
            )}

            <TouchableOpacity
              style={this.styles.button}
              activeOpacity={0.5}
              onPress={() => this.install()}
              disabled={this.state.installDisabled}
            >
              <Text style={this.styles.textStyle}>Telepítés</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
    )
  }
}
