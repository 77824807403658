// Example of Splash, Login and Sign Up in React Native
// https://aboutreact.com/react-native-login-and-signup/
import 'react-native-gesture-handler'

// Import React and Component
import React, { FunctionComponent, useEffect, useState } from 'react'

// Import Navigators from React Navigation
import {
  createNavigationContainerRef,
  DefaultTheme,
  NavigationContainer,
} from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { StyleSheet, SafeAreaView } from 'react-native'
import LinkingConfiguration from './LinkingConfiguration'
import Colors from '../constants/Colors'
import { LoginScreen } from '../screens/LoginScreen'
import { RootNavigator } from './RootNavigator'
import { SettingsScreen } from '../screens/SettingsScreen'
import { HelpScreen } from '../screens/HelpScreen'
import { NotFoundScreen } from '../screens/NotFoundScreen'
import { CategoriesScreen } from '../screens/CategoriesScreen'
import { PreferredLanguageScreen } from '../screens/PreferredLanguageScreen'
import {
  ContentTagsConsumer,
  ContentTagsProvider,
  SentimentTagsProvider,
  SourceTagsProvider,
  UserConsumer,
} from '../context'
import { PreferredTagsProvider } from '../context/preferred-tags.context'
import { NotificationScreen } from '../screens/NotificationScreen'
import { PDFReaderScreen } from '../screens/PDFReaderScreen'
import { AnalysisRootNavigator } from './AnalysisRootNavigator'
import { ScreenSizeConsumer } from '../context/screen-size.context'
import { HeaderComponent } from '../components/HeaderComponent'
import NewContentTagsModal, {
  checkContentTagsWithLS,
} from '../components/NewContentTagsModal'
import { ITag } from '../api/tags'
import { LanguagesProvider } from '../context/languages.context'
import NewLanguageFeatureModal from '../components/NewLanguageFeatureModal'
import { InternalArticleScreen } from '../screens/InternalArticleScreen'
import { multiLanguageDisabled } from '../constants/Config'
import { ConnectionScreen } from '../screens/ConnectionScreen'

const Stack = createNativeStackNavigator()

export interface IAuthNavigationProps {}

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: Colors.oxfordBlue,
  },
})

export const navigationRef = createNavigationContainerRef()

export function navigate(
  name: keyof ReactNavigation.RootParamList,
  params?: {}
) {
  if (navigationRef.isReady()) {
    navigationRef.navigate(name, params)
  }
}

export const AuthNavigation: FunctionComponent<IAuthNavigationProps> = () => {
  const [isNewContentTagsModalVisible, setIsNewContentTagsModalVisible] =
    useState(false)
  const [contentTagsDifference, setContentTagsDifference] = useState<ITag[]>([])

  return (
    <UserConsumer>
      {({ token }) => (
        <LanguagesProvider token={token}>
          <SourceTagsProvider token={token}>
            <SentimentTagsProvider token={token}>
              <ContentTagsProvider token={token}>
                <PreferredTagsProvider>
                  <ScreenSizeConsumer>
                    {({ height }) => (
                      <SafeAreaView
                        style={[
                          styles.safeArea,
                          {
                            maxHeight: height,
                          },
                        ]}
                      >
                        <>
                          <ContentTagsConsumer>
                            {({ isLoading, tags }) => {
                              if (
                                !isLoading &&
                                tags.length &&
                                !isNewContentTagsModalVisible
                              ) {
                                const checkedContentTagsDifference =
                                  checkContentTagsWithLS(tags)
                                if (checkedContentTagsDifference.length) {
                                  setContentTagsDifference(
                                    checkedContentTagsDifference
                                  )
                                  setIsNewContentTagsModalVisible(true)
                                }
                              }
                              return (
                                <NewContentTagsModal
                                  isVisible={isNewContentTagsModalVisible}
                                  setVisible={setIsNewContentTagsModalVisible}
                                  newContentTags={contentTagsDifference}
                                  fetchedContentTags={tags}
                                />
                              )
                            }}
                          </ContentTagsConsumer>

                          {token && !multiLanguageDisabled ? (
                            <NewLanguageFeatureModal />
                          ) : null}
                          <NavigationContainer
                            linking={LinkingConfiguration}
                            theme={DefaultTheme}
                            ref={navigationRef}
                          >
                            <Stack.Navigator
                              screenOptions={{
                                header: (props) => (
                                  <HeaderComponent {...props} />
                                ),
                              }}
                            >
                              {!token ? (
                                <Stack.Screen
                                  name="Login"
                                  component={LoginScreen}
                                  options={{ headerShown: false }}
                                />
                              ) : (
                                <>
                                  <Stack.Screen
                                    name="Home"
                                    component={RootNavigator}
                                    options={{ headerShown: false }}
                                  />
                                  <Stack.Screen
                                    name="Analysis"
                                    component={AnalysisRootNavigator}
                                    options={{ headerShown: false }}
                                  />
                                  <Stack.Screen
                                    name="ArticleList"
                                    component={InternalArticleScreen}
                                    options={{ headerTitle: 'Sajtószemle' }}
                                  />
                                  <Stack.Screen
                                    name="PDFReader"
                                    component={PDFReaderScreen}
                                    options={{ headerTitle: 'Elemzés' }}
                                  />
                                  <Stack.Screen
                                    name="Settings"
                                    component={SettingsScreen}
                                    options={{
                                      headerTitle: 'Beállítások',
                                    }}
                                  />
                                  <Stack.Screen
                                    name="Categories"
                                    component={CategoriesScreen}
                                    options={{
                                      headerTitle: 'Kategóriák',
                                    }}
                                  />
                                  <Stack.Screen
                                    name="PreferredLanguage"
                                    component={PreferredLanguageScreen}
                                    options={{
                                      headerTitle: 'Cikkek elsődleges nyelve',
                                    }}
                                  />
                                  <Stack.Screen
                                    name="Notification"
                                    component={NotificationScreen}
                                    options={{
                                      headerTitle: 'Értesítések',
                                    }}
                                  />
                                  <Stack.Screen
                                    name="Connection"
                                    component={ConnectionScreen}
                                    options={{
                                      headerTitle: 'Kapcsolat',
                                    }}
                                  />
                                  <Stack.Screen
                                    name="Help"
                                    component={HelpScreen}
                                    options={{ headerTitle: 'Segítség' }}
                                  />
                                  <Stack.Screen
                                    name="NotFound"
                                    options={{ headerShown: false }}
                                    component={NotFoundScreen}
                                  />
                                </>
                              )}
                            </Stack.Navigator>
                          </NavigationContainer>
                        </>
                      </SafeAreaView>
                    )}
                  </ScreenSizeConsumer>
                </PreferredTagsProvider>
              </ContentTagsProvider>
            </SentimentTagsProvider>
          </SourceTagsProvider>
        </LanguagesProvider>
      )}
    </UserConsumer>
  )
}
