export enum BrowserType {
  Chrome = 'Chrome',
  Firefox = 'Firefox',
  Safari = 'Safari',
  Opera = 'Opera',
  Edge = 'Edge',
  Unknown = 'Unknown',
}

class Browser {
  public readonly isStandalone: boolean

  public readonly browser: BrowserType

  public readonly isIOS: boolean

  public readonly isMobile: boolean

  public readonly whereIsShare: string

  public readonly showIOSSafariHow2: boolean

  public readonly isSupportingBrowser: boolean

  constructor() {
    const userAgent = navigator.userAgent.toLowerCase()
    if (userAgent.match(/chrome|chromium|crios/i)) {
      this.browser = BrowserType.Chrome
    } else if (userAgent.match(/firefox|fxios/i)) {
      this.browser = BrowserType.Firefox
    } else if (userAgent.match(/safari/i)) {
      this.browser = BrowserType.Safari
    } else if (userAgent.match(/opr\//i)) {
      this.browser = BrowserType.Opera
    } else if (userAgent.match(/edg/i)) {
      this.browser = BrowserType.Edge
    } else {
      this.browser = BrowserType.Unknown
    }
    this.isStandalone = window.matchMedia('(display-mode: standalone)').matches
    this.isMobile = /mobile/.test(userAgent)
    this.whereIsShare = /ipad/.test(userAgent) ? 'tetején' : 'alján'
    this.isIOS =
      /ipad|iphone|ipod/.test(userAgent) ||
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    this.showIOSSafariHow2 =
      this.browser === BrowserType.Safari && this.isIOS && !this.isStandalone
    this.isSupportingBrowser =
      window.hasOwnProperty('BeforeInstallPromptEvent') &&
      (this.showIOSSafariHow2 || this.browser === BrowserType.Chrome)
  }
}

export default new Browser()
