import React, { FunctionComponent, createRef, useEffect, useState } from 'react'
import {
  Image,
  KeyboardAvoidingView,
  ScrollView,
  View,
  Text,
  StyleSheet,
} from 'react-native'
import Colors from '../constants/Colors'
import originalSrc from '../variants/original/logo.png'
import mirrorSrcfrom from '../variants/mirror/logo.png'
import { isMirror } from '../constants/Config'

export const InvalidRegistrationTokenComponent = () => {
  const imgSrc = isMirror ? mirrorSrcfrom : originalSrc

  return (
    <View style={styles.container}>
      <View style={styles.mainBody}>
        <ScrollView
          keyboardShouldPersistTaps="handled"
          contentContainerStyle={{
            flex: 1,
            marginTop: 25,
            alignContent: 'center',
            justifyContent: 'center',
          }}
        >
          <View>
            <KeyboardAvoidingView enabled>
              <View style={styles.imageWrapper}>
                <Image source={{ uri: imgSrc }} style={styles.image}></Image>
              </View>
              <Text style={styles.title}>
                Ezzel a meghívóval már regisztráltak.
              </Text>
            </KeyboardAvoidingView>
          </View>
        </ScrollView>
      </View>
    </View>
  )
}

export default InvalidRegistrationTokenComponent

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: Colors.listWhite,
  },
  mainBody: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center',
    backgroundColor: Colors.white,
    maxWidth: 700,
    width: '100%',
    marginRight: 'auto',
    marginLeft: 'auto',
    paddingRight: 20,
    paddingLeft: 20,
  },
  description: {
    color: Colors.oxfordBlue,
    fontSize: 16,
    marginTop: 10,
    marginBottom: 25,
    fontFamily: 'Roboto_400Regular',
  },
  title: {
    fontSize: 24,
    color: Colors.oxfordBlue,
    textAlign: 'center',
    marginTop: 25,
    marginBottom: 25,
    fontFamily: 'Roboto_500Medium',
  },
  imageWrapper: { alignSelf: 'center', marginBottom: 75 },
  image: {
    height: 200,
    width: 200,
  },
})
